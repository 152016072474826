import * as React from "react";
import { navigate, type HeadFC } from "gatsby";
import PartContent from "../../../components/parts/PartContent";
import { useEffect } from "react";

const PartPage = ({ params }: { params: any }) => {
  const part_type = "motherboard";
  let part_id: string = "";
  if (!!params && params[`part_id`]) {
    part_id = params[`part_id`];
  }

  useEffect(() => {
    if (!!params && !part_id) {
      navigate("/404/");
    }
  }, [part_id]);

  return <PartContent part_id={part_id} part_type={part_type} />;
};

export default PartPage;

export const Head: HeadFC = () => <title>Motherboard - BitLot.app</title>;
